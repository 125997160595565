import {
    decodeAddress, encodeAddress,
} from "@polkadot/util-crypto";
import axios from "axios";

export default class StakelinkAPI {

    endpoint = 'https://data.stakelink.io'

    decode(address) {
        return Buffer.from(decodeAddress(address)).toString('hex');
    }

    validator(stash, token) {
        return axios.get(this.endpoint + '/'+ token.name +'/validator/' + stash + '.json?293').then(res => {

            console.log('res', res)
            // let data = {}
            // data = {...data, nominators: Object.entries(res.data.stake.nominators).map((item, key) => { return {stash:item[0], bonded:item[1]/ token.decimals}})}
            //
            // const eras = Object.entries(res.data.eras).map((item, key) => {
            //
            //
            //     return {
            //         amount: item[1].rewards.amount / token.decimals,
            //         era: parseInt(item[0]),
            //         nominators: item[1].stakers.others,
            //         rewardsClaimed: item[1].rewards.claimed,
            //         ownStake: item[1].stakers.own / token.decimals,
            //         totalStake: item[1].stakers.total / token.decimals,
            //         nominatorsStake: (parseFloat(item[1].stakers.total) - parseFloat(item[1].stakers.own)) / token.decimals,
            //     }
            // })
            //
            // const inclusion = this.calculateInclusion(eras)
            //
            // const totalNominatorsStake = Object.entries(res.data.nominators).reduce((a, b) => { return a+(b[1]/ token.decimals)},0)
            // data = {
            //     ...data,
            //     eras: eras,
            //     totalRewards: res.data.rewards.amount / token.decimals,
            //     inclusion: inclusion,
            //     token: token.token,
            //     tokenName: token.name,
            //     stash: token.stash,
            //     totalNominatorsStake: totalNominatorsStake
            // }

            return {...res.data, token}
        }).catch(error => {
            console.log(error)
            return false
        });

    }

    ranking(token) {
        return axios.get(this.endpoint + '/'+ token.name +'/ranking.json').then(res => {

            console.log('res', res)
            return res.data
        }).catch(error => {
            console.log(error)
            return false
        });

    }


    calculateInclusion(eras) {

        const min = eras.reduce((prev, curr) => {
            return prev.era < curr.era ? prev : curr
        });

        const max = eras.reduce((prev, curr) =>
            prev.era > curr.era ? prev : curr
        );

        const totalEras = max.era - min.era
        const totalActiveEras = eras.length
        const inclusion = (totalActiveEras / totalEras) * 100
        return parseFloat(inclusion).toFixed(2)
    }

}