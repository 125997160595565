import React, {useEffect, useState} from 'react';
import {api} from "../../API/PolkadotJS/connection";
import config from "../../topvalidators.config"

const InputValidateAmount = ({stash, setErrors, setAmount, amount}) => {



    async function balances() {


        const existencialDeposit = await api.then(async (api) => (await api.consts.balances.existentialDeposit))


        const balance = await api.then((api) =>
            api.derive.balances.all(stash).then((res) =>
                (res.freeBalance)
            ))

        return [balance, existencialDeposit]
    }

    useEffect(() => {

        if (stash && amount != null) {

            let newError = null
            balances().then(([balance, existencialDeposit]) => {

                const value = amount * config.info.decimals
                if (value > (balance-existencialDeposit)) {
                    newError = 'The specified value is too large and does not allow funds to pay future transaction fees.'
                } else if (value < existencialDeposit) {
                    newError = `The bonded amount is less than the minimum bond amount of ${existencialDeposit / config.info.decimals}  ${config.info.token}`

                }
                setErrors(newError)


            })

        }
    }, [amount, stash])

    useEffect(() => {

        if(stash) {
            balances().then(([balance, existencialDeposit]) => {
                console.log(balance - existencialDeposit);
                setAmount(Math.max((balance - existencialDeposit)/ config.info.decimals, 0))
            })
        }

    },[stash])



    return (
        <div>
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
                <input type="number" onChange={(amount) => {
                    setAmount(amount.target.value)
                }}
                       value={amount}
                       className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:shadow-outline w-full pr-10"/>
                <span
                    className="z-10 h-full text-sm leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
    <span className='text-xs'>{config.info.token}</span></span>
            </div>

        </div>
    );
};

export default InputValidateAmount;