import {
    web3Accounts,
    web3Enable,
} from '@polkadot/extension-dapp';

import {encodeAddress} from '@polkadot/util-crypto'

import React, {useEffect, useState} from 'react';
import Modal from './components/Modal'
import Select from "react-select";
import api from "./API/PolkadotJS/connection";
import InputValidateController from './components/Staking/InputValidateController'
import InputValidateAmount from "./components/Staking/InputValidateAmount";
import dotextension from './img/dotextension.svg'
import ledger from './img/ledger.svg'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCopy} from "@fortawesome/free-regular-svg-icons";
import Polkadotjsextension from "./components/Staking/Polkadotjsextension";

const Steps = ({step, setStep, showSteps, setShowSteps, targets}) => {


    const [method, setMethod] = useState(null);
    const [modalTitle, setModalTitle] = useState('Choose a method');
    const [nextButton, setNextButton] = useState(null);
    const [copySuccess, setCopySuccess] = useState(false);


    useEffect(() => {
        setModalTitle('Choose a method')
        setCopySuccess(false)
    }, [showSteps])

    function handleClose() {
        setShowSteps(null)
        setMethod(null)
        setNextButton(null)

    }

    const copyClipboard = async () => {
        await navigator.clipboard.writeText(targets.toString())
        setCopySuccess(true)
    }


    if (!showSteps) {
        return null
    }

    const switchRender = () => {

        if (method == 'extension') {
            return <Polkadotjsextension setModalTitle={setModalTitle} targets={targets} setNextButton={setNextButton}/>
        } else {
            return <div>
                <p className='mb-5'>Choose the method you prefer:</p>
                <div className='grid grid-cols-2 gap-6 text-center'>
                    <div
                        className='p-10 shadow-lg bg-gray-700 text-white hover:border-pink-600  border-4 border-gray-700 cursor-pointer'
                        onClick={() => setMethod('extension')}><img src={dotextension}
                                                                    className='h-20 m-auto mb-3'/>
                        <div>Polkadot.js extension</div>
                    </div>
                    <div
                        className='p-10 shadow-lg bg-gray-700 text-white hover:border-pink-600  border-4 border-gray-700 cursor-pointer'
                        onClick={copyClipboard}
                    >
                        {copySuccess ?
                            <div>
                                <FontAwesomeIcon icon={faCopy} size='4x' className='h-20 m-auto mb-3' color='green'/>
                                <div className='font-bold'>Copied!</div>
                            </div> :
                            <div>
                                <FontAwesomeIcon icon={faCopy} size='4x' className='h-20 m-auto mb-3'/>
                                <div>Copy to Clipboard</div>
                            </div>}

                    </div>
                </div>
            </div>
        }


    }


    return (
        <Modal onClose={handleClose} showModal={true} title={modalTitle} nextButton={nextButton}>
            {switchRender()}
        </Modal>

    );
};

export default Steps;