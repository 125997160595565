import React, {useEffect} from 'react'
import {hexToString} from '@polkadot/util';
import Identicon from '@polkadot/react-identicon';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEnvelope, faExternalLinkAlt, faLink} from "@fortawesome/free-solid-svg-icons";
import {faTwitter} from "@fortawesome/free-brands-svg-icons";
import PromotedValidators from "./PromotedValidators";

import {connect} from "react-redux";

import {boot} from "../../store/dataActions";
const Table = ({validators, targets, setTargets, maxValidators, connected, provider}) => {



    function abbrNum(number, decPlaces) {
        // 2 decimal places => 100, 3 => 1000, etc
        decPlaces = Math.pow(10,decPlaces);

        // Enumerate number abbreviations
        var abbrev = [ "k", "m", "b", "t" ];

        // Go through the array backwards, so we do the largest first
        for (var i=abbrev.length-1; i>=0; i--) {

            // Convert array index to "1000", "1000000", etc
            var size = Math.pow(10,(i+1)*3);

            // If the number is bigger or equal do the abbreviation
            if(size <= number) {
                // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                // This gives us nice rounding to a particular decimal place.
                number = Math.round(number*decPlaces/size)/decPlaces;

                // Handle special case where we round up to the next abbreviation
                if((number == 1000) && (i < abbrev.length - 1)) {
                    number = 1;
                    i++;
                }

                // Add the letter for the abbreviation
                number += abbrev[i];

                // We are done... stop
                break;
            }
        }

        return number;
    }


    const isChecked = (address) => {
        return targets.find((target) => {
            return target.address === address
        })
        return false
    }

    const onChange = (e) => {

        if (!e.target.checked) {
            setTargets(
                targets.filter(function (target, index) {
                    return e.target.name !== target.address
                })
            );
        } else {

            validators.map(function (validator, index) {
                if(e.target.name == validator.address)
                {
                    setTargets([...targets, validator]);
                }
            })

        }

    }



    if (validators && validators.map) {
        return (

            <table className="table-auto text-center w-full col-span-2">
                <thead className="bg-gray-700 text-white">
                <tr>
                    <th className=""></th>
                    <th className="py-3">Rank</th>
                    <th>Identity</th>
                    <th className="">Name</th>
                    <th className="">Nominators</th>
                    <th className="">Inactive Nominators</th>
                    <th className="">Min Stake</th>
                    <th className="">Self Staked</th>
                    <th className="">Total Staked</th>
                    <th className="">Inclusion</th>
                    <th className="">Avg.Points</th>
                    <th className="">Commission</th>
                    <th className="">APY</th>

                </tr>
                </thead>
                <tbody>

                {/*<PromotedValidators validators={validators} targets={targets} onChange={onChange} maxValidators={maxValidators} abbrNum={abbrNum} isChecked={isChecked}/>*/}
                {validators.map((item, index) => {

                    return (
                        <tr className="bg-white border-b border-gray-200" key={index}>
                            {isChecked(item.address) ?
                                <td><input type="checkbox" name={item.address} checked onChange={onChange}
                                           className='h-6 w-6'/></td>
                                : (targets.length == maxValidators || !connected) ?
                                    <td><input type="checkbox" name={item.address} disabled onChange={onChange}
                                               className='h-6 w-6'/>
                                    </td> : <td><input type="checkbox" name={item.address} onChange={onChange}
                                                       className='h-6 w-6'/>
                                    </td>}
                            <td className="p-3 font-bold">{index + 1}</td>
                            <td>
                                <div className='items-center flex justify-center space-x-2'>
                                    <Identicon
                                        value={item.address}
                                        size='24'
                                        theme='polkadot'

                                    />
                                    {item.identity.info.email.raw ?
                                        <a href={"mailto:" + hexToString(item.identity.info.email.raw)} target='_blank'><FontAwesomeIcon
                                            icon={faEnvelope} color='green'/></a> : null}
                                    {item.identity.info.web.raw ? <a href={hexToString(item.identity.info.web.raw)}
                                                                     target='_blank'><FontAwesomeIcon icon={faLink}

                                                                                                      color='#0645AD'/></a> : null}
                                    {item.identity.info.twitter.raw ?
                                        <a href={"https://www.twitter.com/" + hexToString(item.identity.info.twitter.raw)}
                                           target='_blank'><FontAwesomeIcon icon={faTwitter}  color='#00acee'/></a> : null}
                                </div>
                            </td>
                            <td>
                                {hexToString(item.identity.info.display.raw)}
                            </td>
                            <td>{item.nominators}</td>
                            <td>{item.totalNominators - item.nominators}</td>
                            <td>{(item.minStake/provider.decimals).toFixed(2)} {provider.token}</td>
                            <td>{abbrNum(item.selfStaked.toFixed(2),2)} {provider.token}</td>
                            <td>{abbrNum(item.totalStaked,2)} {provider.token}</td>
                            <td>{item.inclusion.toFixed(2).toLocaleString()}%</td>
                            <td>{((item.points / item.activeEras).toFixed(0)).toLocaleString()}</td>
                            <td>{(item.commission).toLocaleString()}%</td>
                            <td>{item.apy ? item.apy.toFixed(2).toLocaleString() : null}%</td>


                        </tr>
                    )
                })}

                </tbody>
            </table>

        )
    }

}

const mapStateToProps = ({data}) => ({
    accounts: data.accounts,
    connected: data.connected,
    provider: data.provider,
})

const mapDispatchProps = {

}

export default connect(mapStateToProps, mapDispatchProps)(Table);