import React, {useEffect, useState} from 'react'
import {web3Accounts, web3Enable, web3FromSource} from "@polkadot/extension-dapp"
import {encodeAddress} from "@polkadot/util-crypto"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCopy} from "@fortawesome/free-regular-svg-icons"
import Select from "react-select"
import InputValidateController from "./InputValidateController"
import InputValidateAmount from "./InputValidateAmount"
import dotextension from '../../img/dotextension.svg'
import {faChrome} from "@fortawesome/free-brands-svg-icons";
import {api} from '../../API/PolkadotJS/connection'
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Alert from "../Alert";
import {hexToString} from "@polkadot/util";

const Polkadotjsextension = ({setModalTitle, targets, setNextButton}) => {

    const [injectedAccounts, setInjectedAccounts] = useState(null);
    const [accounts, setAccounts] = useState(null);

    const [errors, setErrors] = useState(null);
    const [warnings, setWarnings] = useState(null);
    const [txStatus, setTxStatus] = useState(null);

    const [amountErrors, setAmountErrors] = useState(null);
    const [amount, setAmount] = useState(null);
    const [step, setStep] = useState('loading');
    const [controller, setController] = useState(null);
    const [stash, setStash] = useState(null);
    const [paymentDestination, setPaymentDestination] = useState(null);
    const [txFees, settxFees] = useState(null);
    const dotDec = 1000000000000

    const nextButton = (enabled) => {

        if (enabled && step == "connected") {
            return (<button
                className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"

                style={{transition: "all .15s ease"}}
                onClick={() => setStep('confirmation')}
            >
                Next >
            </button>)
        }
        if (enabled && step == "confirmation") {
            return (<button
                className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"

                style={{transition: "all .15s ease"}}
                onClick={() => bondNominate(true)}
            >
                Bond & Nominate
            </button>)
        }
        return (<button
            className="bg-green-500 opacity-50  cursor-not-allowed text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
            type="button"

            style={{transition: "all .15s ease"}}
        >
            Next >
        </button>)

    }


    const accountMetaByAddress = (address) => {

        const _accounts = accounts.filter((account) => {
            let _address = encodeAddress(account.address, 2)
            if (_address === address) {
                return true
            }
            return false
        })
        return _accounts[0].meta
    }

    const bondNominate = async (send) => {

        let stashMeta = await accountMetaByAddress(stash)
        let controllerMeta = await accountMetaByAddress(controller)
        const _injectorStash = await web3FromSource(stashMeta.source);
        const _injectorController = await web3FromSource(controllerMeta.source);
        let _api = await api

        let nominatedTargets = []

        targets.map((target) => {
            nominatedTargets = [...nominatedTargets, target.address]
        })
        console.log(nominatedTargets)

        let txs = [
            await _api.tx.staking.bond(stash, amount * dotDec, paymentDestination),
            await _api.tx.staking.nominate(nominatedTargets),
            await _api.tx.staking.setController(controller)
        ]
        if (stash == controller) {
            txs = [
                await _api.tx.staking.bond(stash, amount * dotDec, paymentDestination),
                await _api.tx.staking.nominate(nominatedTargets)
            ]
        }


        let paymentInfo = await _api.tx.utility.batchAll(txs).paymentInfo(stash);
        settxFees(paymentInfo.partialFee.toHuman())

        if(send) {

            setStep('sendingTx')
            setNextButton(null)


            _api.tx.utility.batchAll(txs).signAndSend(stash, {signer: _injectorStash.signer}, ({status}) => {
                if (status.isInBlock) {
                    console.log(`Completed at block hash #${status.asInBlock.toString()}`);
                    setTxStatus({isError: false, block: status.asInBlock.toString(), error: null})
                } else {
                    console.log(`Current status: ${status.type}`);
                }
            }).catch((error) => {
                let _txStatus = txStatus
                console.log(':( transaction failed', error);
                setTxStatus({isError: true, block: null, error: error.toString()})
            })
        }


    }

    const paymentDestinationOptions = [
        {
            'value': 0,
            'label': 'Stash Account (Increase the amount of stake)'
        },
        {
            'value': 1,
            'label': 'Stash Account (Do not Increase the amount of stake)'
        },
        {
            'value': 2,
            'label': 'Controller Account (Increase the amount of stake)'
        },
    ]

    useEffect(() => {
        setModalTitle('Trying to connect')

        connectExtension().then((res) => {
            if (res) {
                getAccounts()
                setModalTitle('Setup Nomination 1/2')
                setStep('connected')
                setNextButton(nextButton(false))


            } else {
                setStep('needToConnect')
                setModalTitle('Install the extension')
            }
        })

    }, [])

    useEffect(() => {
        if (!errors && !amountErrors && stash && controller && amount && paymentDestination !== null) {

            setNextButton(nextButton(true))
        } else if (errors || amountErrors) {
            setNextButton(nextButton(false))
        }


    }, [stash, controller, amount, paymentDestination, errors, amountErrors])

    useEffect(() => {
        if (step == 'confirmation') {
            setModalTitle('Confirm Nomination 2/2')
            bondNominate(false)
            setNextButton(nextButton(true))
        } else if (step == 'sendingTx') {
            setModalTitle('Sending the transaction')
            setTxStatus(null)
        }

    }, [step])

    async function connectExtension() {
        const extensions = await web3Enable('KusamaValidators')
        if (extensions.length === 0) {
            return false
        }
        return true

    }

    async function getAccounts() {

        const allAccounts = await web3Accounts();
        setAccounts(allAccounts)
        let options = await Promise.all(allAccounts.map(async (item, index) => {

                return ({
                    'value': encodeAddress(item.address, 2),
                    'label': item.meta.name + ": " + encodeAddress(item.address, 2)
                })

            }
        ))

        if (!options.length) {
            // setStep("hasNotAccounts")
            return;
        }

        setInjectedAccounts(options)
        // setStep("hasAccounts")


    }

    if (step == 'loading') {
        return <div className='flex'>
            <svg className="animate-spin h-5 w-5 mr-3 bg-pink-600" viewBox="0 0 24 24">
            </svg>
            <span>Connecting to your extension ...</span></div>
    } else if (step == 'sendingTx') {
        return <div>

            {!txStatus ?
                <div className='flex justify-center'>
                    <svg className="animate-spin h-5 w-5 mr-3 bg-pink-600" viewBox="0 0 24 24">
                    </svg>
                    <span>Awaiting your signature</span>
                </div>
                :
                <div className='mt-3 flex-col text-center'>

                    {!txStatus.isError ?
                        <div className='flex flex-col justify-center'>
                            <FontAwesomeIcon icon={faCheckCircle} size='5x' className='m-auto' color='green'/>
                            <h2 className='my-5'>Completed at block hash</h2>
                            <span className='bg-green-300 p-2 text-green-900'><a
                                href={'https://kusama.subscan.io/block/' + txStatus.block}
                                target='_blank'>{txStatus.block}</a></span>
                        </div>
                        :
                        <div className='flex flex-col justify-center'>
                            <FontAwesomeIcon icon={faTimesCircle} size='5x' className='m-auto' color='red'/>
                            <span className='mt-5'>{txStatus.error}</span>
                        </div>
                    }
                </div>
            }

        </div>
    } else if (step == 'connected') {
        return (
            <div className='grid grid-cols-3 gap-4'>

                <div className='col-span-2'>

                    <div>Choose your Stash Account:</div>
                    <Select options={injectedAccounts} onChange={(stash) => setStash(stash.value)}
                            name='stash'/>
                </div>
                <div className='space-y-4 text-justify bg-gray-100 p-3 text-sm row-span-2'>
                    <p>Think of the stash as your cold wallet and the controller as your hot wallet. Funding
                        operations are controlled by the stash, any other non-funding actions by the controller
                        itself.
                    </p>
                    <p> To ensure optimal fund security using the same stash/controller is strongly discouraged,
                        but
                        not forbidden.</p>
                </div>
                <div className='col-span-2'>
                    <div> Choose your Controller Account:</div>
                    <InputValidateController
                        options={injectedAccounts}
                        setController={setController}
                        setErrors={setErrors}
                        stash={stash}
                        controller={controller}
                        setWarnings={setWarnings}
                    />
                </div>
                {errors ?
                    <Alert type='error' message={errors}/> : null}
                {warnings ?
                    <Alert type={'warning'} message={warnings}/> : null}

                <div className='col-span-2'>
                    Staking Amount:
                    <InputValidateAmount stash={stash} setErrors={setAmountErrors} setAmount={setAmount}
                                         amount={amount}/>
                </div>
                <div className='space-y-4 text-justify bg-gray-100 p-3 text-sm '>
                    <p>The amount placed at-stake should not be your full available available amount to allow
                        for
                        transaction fees.</p>

                </div>
                {amountErrors ? <Alert message={amountErrors} type='error'/> : null}
                <div className='col-span-2'>
                    <div>on-chain bounding duration:</div>
                    <div>7 days</div>
                </div>
                <div className='space-y-4 text-justify bg-gray-100 p-3 text-sm '>

                    <p> Once bonded, it wil need to be unlocked/withdrawn and will be locked for at least the
                        bonding duration. </p>
                </div>

                <div className='col-span-2'>
                    <div>Payments Destination:</div>
                    <div><Select options={paymentDestinationOptions} onChange={(e) => setPaymentDestination(e.value)}/>
                    </div>
                </div>
                <div className=' text-justify bg-gray-100 p-3 text-sm'>
                    <p>Rewards (once paid) can be deposited to either the stash or controller, with different
                        effects.</p>

                </div>


            </div>

        )
    } else if (step == 'needToConnect') {


        return (
            <div>
                <p>You need to install the extension Polkadot JS Extension to use this method</p>
                <div className='text-center space-y-3 pt-5'>
                    <img src={dotextension} className='h-20 p-3 m-auto'/>
                    <button
                        className=' m-auto text-center bg-yellow-600 text-white active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1'
                        type="button"
                        onClick={() => window.open("https://chrome.google.com/webstore/detail/polkadot%7Bjs%7D-extension/mopnmbcafieddcagagdcbnhejhlodfdd", '_blank')}>
                        <FontAwesomeIcon icon={faChrome} size='lg'/> Download for Chrome
                    </button>
                </div>
            </div>
        )
    } else if (step == 'confirmation') {
        return <div className='grid grid-cols-3 gap-3'>

            <div className=' p-2'>Stash</div>
            <div className='border border-dashed p-2 col-span-2'>{stash}</div>

            <div className='  p-2'>Controller</div>
            <div className='border border-dashed p-2 col-span-2'>{controller}</div>

            <div className='  p-2'>Staking Amount</div>
            <div className='border border-dashed p-2 col-span-2'>{amount}</div>
            <div className='  p-2'>Destination Reward</div>
            <div className='border border-dashed p-2 col-span-2'>{paymentDestinationOptions[paymentDestination].label}</div>

            <div className='  p-2'>Validators</div>
            <div className='border border-dashed p-2 col-span-2'>
                {targets.map((target) => {
                    return <div> {hexToString(target.identity.info.display.raw)} </div>
                })}
            </div>
            <div className='  p-2'>Fees</div>
            <div className='border border-dashed p-2 col-span-2'>{txFees}</div>


        </div>
    } else {
        return <div>Unexpected error, load it again</div>
    }
}


export default Polkadotjsextension;