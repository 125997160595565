import {web3Accounts, web3Enable} from "@polkadot/extension-dapp";
import {addressEq, encodeAddress} from "@polkadot/util-crypto";
import {toast} from "react-toastify";
import Api from "../API/PolkadotJS/Api";
import StakelinkAPI from "../API/StakelinkAPI";
import Subscan from "../API/Subscan";


const slAPI = new StakelinkAPI()
const subScanApi = new Subscan()


export const getAccounts = () => {


    return async (dispatch, getState) => {
        const allAccounts = await web3Accounts();
        console.log(allAccounts)

        const token = getState().data.provider
        const api = getState().data.ws
        if (!api) return false

        let options = await Promise.all(allAccounts.map(async (item) => {
            return api.getAccountData(item, token)
        }))
        console.log(options)


        dispatch({type: 'setAccounts', payload: options})

        const stashAccounts = options.filter((acc) => (acc.is_stash))
        dispatch({type: 'setStashAccounts', payload: stashAccounts})

    }
}

export const setConnected = () => {

    return async (dispatch, getState) => {
        dispatch({type: 'setConnected', payload: null})
        const extensions = await web3Enable('Stake Link')
        if (extensions.length === 0) {
            toast.error("Install Polkadot.js extension");
            dispatch({type: 'setConnected', payload: false})
        } else {
            await dispatch(getAccounts())
            dispatch({type: 'setConnected', payload: true})


        }

    }

}


export const setProvider = (provider) => {

    return async (dispatch, getState) => {
        dispatch({type: 'setAppReady', payload: false})
        getState().data.ws.disconnect(provider.provider)
        const api = new Api(provider.provider)
        await api.connect()

        dispatch({type: 'setWs', payload: api})
        dispatch({type: 'setProvider', payload: provider})
        if (getState().data.connected) {
            await dispatch(getAccounts())
        }
        dispatch({type: 'setAppReady', payload: true})


    }

}


export const boot = () => {

    return async (dispatch, getState) => {


        let tokens = [
            {
                name: 'polkadot',
                token: 'dot',
                decimals: 10000000000,
                provider: 'wss://rpc.polkadot.io/',
                stash: '1sh6y8SMuseeuxn8JyW4eUHbJrLevi7ASH1TJQqb3DFjAYe'
            },
            {
                name: 'kusama',
                token: 'ksm',
                decimals: 1000000000000,
                provider: 'wss://kusama-rpc.polkadot.io/',
                stash: 'GetZUSLFAaKorkQU8R67mA3mC15EpLRvk8199AB5DLbnb2E'
            }
        ]

        await fetch('https://api.coingecko.com/api/v3/simple/price?ids=cardano,ethereum,polkadot,kusama&vs_currencies=usd&include_market_cap=true')
            .then(res => res.json())
            .then((data) => {

                console.log(tokens[0])
                // tokens = {tokens[0], price:data['polkadot'].usd}
                tokens = tokens.map((t) =>
                    t.name === 'polkadot' ? {...t, price: data['polkadot'].usd} : {...t, price: data['kusama'].usd}
                )

                dispatch({type: 'setTokens', payload: tokens})
                dispatch({type: 'setProvider', payload: tokens[0]})


            })


        //getting nodes info
        const nodes =  tokens.map((item) => {
            return  slAPI.validator(item.stash, item).then((validator) => {
                return validator ? validator : null

            })
        })


        Promise.all(nodes).then(res => {
            const data = res.filter((b) => b ? true : false)
            console.log('final data', data)
            dispatch({type: 'setNodes', payload: data})
        });


        const rankings =  tokens.map((item) => {
            return slAPI.ranking(item).then((validator) => {
              return {token:item.token, data:validator}
            })
        })


        Promise.all(rankings).then(res => {
            dispatch({type: 'setRankings', payload: res})
        });


        //end getting nodes info


        const api = new Api()
        await api.connect()
        dispatch({type: 'setWs', payload: api})
        dispatch({type: 'setAppReady', payload: true})


    }


}









