
const { ApiPromise, WsProvider } = require("@polkadot/api");
const wsProvider = new WsProvider('wss://rpc.polkadot.io/');
export const api = ApiPromise.create({provider: wsProvider})

// //
// // export const createApi = async (providerUrl = 'wss://rpc.polkadot.io/') =>  {
// //
// //     const provider = new WsProvider(providerUrl);
// //
// //     const api = await ApiPromise.create({ provider });
// //
// //     const [chain, nodeName, nodeVersion] = await Promise.all([
// //         api.rpc.system.chain(),
// //         api.rpc.system.name(),
// //         api.rpc.system.version()
// //     ]);
// //
// //     console.log(`You are connected to chain ${chain.toString()} using ${nodeName.toString()} v${nodeVersion.toString()}`);
// //
// //     return api;
// // }