import axios from "axios";
import config from "../topvalidators.config";

export default class Subscan {

    kusamaValidatorStash = 'GetZUSLFAaKorkQU8R67mA3mC15EpLRvk8199AB5DLbnb2E'
    polkadotValidatorStash = '1sh6y8SMuseeuxn8JyW4eUHbJrLevi7ASH1TJQqb3DFjAYe'
    endpoints = {kusama: 'https://kusama.api.subscan.io/', polkadot: 'https://polkadot.api.subscan.io/'}

    constructor() {


    }

    getPagesRewards(address) {
        return axios.post('https://kusama.api.subscan.io/api/scan/account/reward_slash', {
            address: address,
            page: 0,
            row: 100
        }).then(res => {

            return res.data.data === undefined ? 0 : parseInt(res.data.data.count / 100)

        }).catch(error => {
            console.log(error)
        });
    }

    eraStats(address, token, rows = 100, page = 0) {
        return axios.post('https://kusama.api.subscan.io/api/scan/staking/era_stat', {
            address: address,
            page: page,
            row: rows
        }).then(res => {

           return res.data.data == undefined ?  false:res.data.data.list


        }).catch(error => {
            console.log(error)
        });

    }

    getRewards(address, token, rows = 100, page = 0) {

        return axios.post('https://kusama.api.subscan.io/api/scan/account/reward_slash', {
            address: address,
            page: page,
            row: rows
        }).then(res => {

            if (res.data.data === undefined) return false
            console.log('getRewards', res)
            const rewardsData = res.data.data.list.reduce((prevArray, list) => {
                let params = JSON.parse(list.params)
                const itemFoundIndex = prevArray.findIndex(item => item.block_num === list.block_num)
                if (itemFoundIndex > -1) {
                    const newValue = (parseFloat(prevArray[itemFoundIndex].value) + parseFloat(params[1].value)) / token.decimals
                    return [...prevArray.slice(0, itemFoundIndex), {
                        block_num: list.block_num,
                        value: newValue
                    }, ...prevArray.slice(itemFoundIndex + 1)]
                } else {
                    return [...prevArray, {
                        block_num: list.block_num,
                        value: parseFloat(params[1].value) / token.decimals
                    }]
                }
            }, [])

            return rewardsData.reverse()


        }).catch(error => {
            console.log(error)
        });


    }


    endpoint(network = 'polkadot') {
        return this.endpoints[network]
    }


    validatorInfo(network = 'polkadot', stash) {

        axios.post(this.endpoint('polkadot') + 'api/scan/staking/waiting', {
            address: stash,
            row: 20,
            page: 0,

        }).then(res => {

            console.log(res)

        }).catch(error => {
            console.log(error)
        });
    }

}