import React, {useEffect, useState} from 'react';
import {hexToString, stringUpperFirst} from "@polkadot/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandSparkles} from "@fortawesome/free-solid-svg-icons";
import Table from "./Table/Table";
import Steps from "../Steps";
import {boot} from "../store/dataActions";
import {connect} from "react-redux";
import axios from "axios";
import Select from "react-select";
import Modal from "./Modal";
import {toast} from "react-toastify";
import {api} from "../API/PolkadotJS/connection";
import {web3FromSource} from "@polkadot/extension-dapp";
import spinner from '../img/spinner.svg'
import Identicon from "@polkadot/react-identicon";
import {getAccounts, setProvider} from "../store/dataActions";
import Ranking from "./Ranking";

const Stake = ({connected, accounts, stashAccounts, usdTokenPrice, tokenName, tokens, getAccounts, provider, setProvider, ws}) => {


    const [validators, setValidators] = useState(null);
    const [Step, setStep] = useState(null);
    const [showSteps, setShowSteps] = useState(false);
    const [targets, setTargets] = useState([]);
    const [stash, setStash] = useState(null);
    const [amount, setAmount] = useState(null);
    const [sendTransaction, setSendTransaction] = useState(false);


    const [showModel, setShowModel] = useState(false);
    const [transactionFees, setTransacionFees] = useState(0);

    const confirmButton = () => {

        if (!sendTransaction)
            return (<button
                className="bg-green-500 flex items-center text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={() => sendTx()}
                style={{transition: "all .15s ease"}}

            >
                Confirm & Sign
            </button>)

        else return (<button
            className="bg-green-500 cursor-not-allowed flex opacity-40 items-center text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
            type="button"
            style={{transition: "all .15s ease"}}

        >
            <img src={spinner} className='w-10 h-10 mr-2'/>
            Confirm & Sign
        </button>)

    }


    const [network, setNetwork] = useState(null);
    const maxValidators = 16;

    useEffect(() => {

        if(provider) {
            // console.log(provider)
            axios.get('https://app.stakelink.io/data/validators-' + provider.name + '.json?time=43423').then(res => {
                setValidators(res.data)
            }).catch(error => {
                console.log(error)
            });
        }


    }, [provider])

    const classStakeButton = connected && targets && targets.length && stash ? "bg-green-500  text-white active:bg-green-600 font-bold uppercase text-base px-8 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" : "bg-green-500  text-white active:bg-green-600 font-bold uppercase text-base px-8 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 opacity-40"
    const customStyles = {
        control: (base, state) => ({
            ...base,
            height: '48px'
        }),
    };


    const handleChange = (val) => {
        setStash(val)
    }

    const initStake = async () => {


        if (stash && stash.available_balance < 0.0016) {
            toast.error("You don't have enough to pay the network fees, please send money to your account");
            return false
        }

        if (connected && accounts && stash && targets.length) {
            const fees = await ws.calculateTransactionFees(stash,amount,targets)
            setTransacionFees((fees/provider.decimals).toFixed(4))
            setAmount((stash.free_balance  - transactionFees).toFixed(2))
            setShowModel(true)
        }
    }


    const sendTx = async () => {
        setSendTransaction(true)
        const _injectorStash = await web3FromSource(stash.meta.source);
        await ws.sendTxNomination(stash,amount,targets,_injectorStash)
        setSendTransaction(false)

    }


    const closeStake = () => {
        setShowModel(false)
    }

    const handleAmountChange = event => {
        let {value, min, max} = event.target;
        // value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setAmount(value);
    };


    if (!provider) return (<div>loading...</div>)


    return (
        <div className='col-span-5 pt-5 '>

            {showModel ?
                <Modal showModal={true} title="Confirm your staking" onClose={closeStake} nextButton={confirmButton()}>

                    <div className='grid grid-cols-2'>
                        <div className=' p-5'>
                            <div>How much do you want to stake?</div>
                            <div className="my-3">
                                <div className='flex items-center'>
                                    <input
                                        type="number"
                                        placeholder="amount"
                                        max={(stash.free_balance  - transactionFees).toFixed(2)}
                                        min={0.00016}
                                        value={amount}
                                        onChange={handleAmountChange}
                                        className="flex-grow px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"/>
                                    <div
                                        className='ml-3 text-xs text-gray-500 underline'> Max. {(stash.free_balance  - transactionFees).toFixed(2)} {provider.token}
                                    </div>
                                </div>
                            </div>
                            <div className='mb-3'>Validators:</div>
                            <div className='max-h-60 overflow-auto border border-gray-400 p-2'>
                                <div className='flex flex-col'>
                                    <div className='flex mb-2'>
                                        <div className='flex-grow underline'>Name</div>
                                        <div className='underline'>~APY</div>
                                    </div>
                                    {targets.map((item) => {
                                        return (
                                            <div className='flex'>
                                                <div className='flex-grow flex items-center'>
                                                    <Identicon
                                                        value={item.address}
                                                        size='24'
                                                        theme='polkadot'

                                                    />

                                                    <div
                                                        className='ml-2'>{hexToString(item.identity.info.display.raw)}</div>
                                                </div>
                                                <div>{item.apy ? item.apy.toFixed(2).toLocaleString() : null}%</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='bg-gray-700 text-gray-200 p-5'>
                            <div className='grid-cols-2 grid '>
                                <div className='col-span-2 text-center mb-4 font-bold font-mono'>Staking Transaction
                                    Details
                                </div>
                                <div>Stash</div>
                                <div className='truncate'>{stash.address}</div>
                                <div>Controller</div>
                                <div className='truncate'>{stash.address}</div>
                                <div>Amount</div>
                                <div>{amount} {provider.token}</div>
                                <div>Nominators</div>
                                <div>{targets.length}</div>
                                <div>~APY</div>
                                <div>14%</div>
                                <div>Lock Period</div>
                                <div>28 days</div>
                                <div>Rewards Destination</div>
                                <div>Stash (compounded)</div>
                                <div>Staking Mechanic</div>
                                <div>Proof of Stake</div>
                                <div>Slashing system</div>
                                <div>Yes</div>
                                <div>Network Fees</div>
                                <div> {transactionFees} {provider.token} (~${(transactionFees * usdTokenPrice).toFixed(2)})</div>

                            </div>
                        </div>

                    </div>

                </Modal> : null}
            {/*<Navbar handleNominate={nominate} targets={targets} setTargets={setTargets} />*/}
            <div className='mb-20'>

                <div className="container mx-auto p-10 ">
                    <div className='flex items-center'>
                        <h2 className="text-3xl font-bold pb-5 flex-grow">Curated list
                            of {stringUpperFirst(provider.name)}'s
                            Validators</h2>
                    </div>

                    <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
                         role="alert">
                        <div className="flex">
                            <div className="py-1">
                                <svg className="fill-current h-6 w-6 text-teal-500 mr-4"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path
                                        d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                                </svg>
                            </div>

                            <div>
                                <p className="font-bold">How it Works</p>
                                <div className='space-y-1'>
                                    <p className="text-sm">
                                        You can earn {provider.token} by nominating one or 16 validators.
                                        By doing so, you become a nominator for the validator(s) of your choice. Stake
                                        Link technology pick up
                                        the best validators that guarantee the best APY and we listed them ordered by
                                        APY. Feel free to make your own investigations and nominate the ones you like
                                        the most.
                                    </p>
                                    <p className="text-sm">
                                        While your {provider.token} are staked by nominating a validator, they are
                                        'locked' (bonded).
                                        You can receive new {provider.token} in your account but you can not
                                        transfer {provider.token} away from your account. You can un-nominate at any
                                        time to stop
                                        staking your funds. There is
                                        an unbonding period of 7 days on {tokenName} before bonded
                                        funds can be transferred after issuing an unbond transaction. You can learn more
                                        about nominating <a href='https://wiki.polkadot.network/docs/en/learn-nominator'
                                                            target='_blank' className='underline'>here.</a>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/*<div className='mb-3 mt-3'>*/}
                    {/*    <button className='bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 py-4 px-10 text-white w-full flex-grow rounded-xl '>*/}
                    {/*        Connect your Wallet*/}
                    {/*        <div className=>*/}
                    {/*        <svg className="animate-spin h-5 w-5 mr-3 bg-white mx-auto" viewBox="0 0 24 24"></svg>*/}
                    {/*        </div>*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    <div className='mt-3 text-gray-700'>Choose your Stash Account:</div>
                    <div className="mb-3 mt-1 flex items-center ">
                        <div className='z-0 flex-grow mr-5'>
                            <Select  onChange={handleChange}
                                    options={accounts ? accounts : null}
                                     styles={customStyles}
                                    isDisabled={!connected || !accounts} name='stash' />
                        </div>
                        <div>
                            <button
                                className={classStakeButton}
                                type="button"
                                onClick={initStake}
                            >
                                <FontAwesomeIcon icon={faHandSparkles}/> NOMINATE
                            </button>
                        </div>

                    </div>

                    <Ranking />
                    {/*{validators ?*/}
                    {/*    <div className='overflow-auto'>*/}
                    {/*        <Table*/}
                    {/*            validators={validators}*/}
                    {/*            targets={targets}*/}
                    {/*            setTargets={setTargets}*/}
                    {/*            maxValidators={maxValidators}*/}
                    {/*            network={network}*/}
                    {/*        />*/}
                    {/*        <div className='mt-3 text-xs text-gray-500 text-right'> List updated*/}
                    {/*            every hour*/}
                    {/*        </div>*/}
                    {/*    </div> :*/}
                    {/*    <div>*/}
                    {/*        Loading...*/}
                    {/*    </div>*/}
                    {/*}*/}

                </div>
                <Steps step={Step} setShowSteps={setShowSteps} showSteps={showSteps}
                       setStep={setStep}
                       targets={targets}/>
            </div>

        </div>
    );
};
const mapStateToProps = ({data}) => ({
    stashAccounts: data.stashAccounts,
    accounts: data.accounts,
    provider: data.provider,
    connected: data.connected,
    usdTokenPrice: data.usdTokenPrice,
    tokens: data.tokens,
    ws: data.ws
})

const mapDispatchProps = {
    boot, getAccounts, setProvider
}

export default connect(mapStateToProps, mapDispatchProps)(Stake)