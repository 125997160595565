import React, {useEffect, useState} from 'react';
import {LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer} from 'recharts';
import axios from "axios";
import config from '../topvalidators.config'
import {connect} from "react-redux";
import {getAccounts} from "../store/dataActions";
import Select from "react-select";


const Dashboard = ({accounts, usdTokenPrice, stashAccounts, connected, totalBalance, lockedBalance, tokens, ws}) => {

    const [rewards, setRewards] = useState(null);
    const [totalRewards, setTotalRewards] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [stash, setStash] = useState(null);



    useEffect(() => {


        if(ws) {
            console.log('connecing')
            ws.genesisHash().then((res) => console.log(res))

        }

        if (stashAccounts && stashAccounts.length) {
            getRewards(stashAccounts[0].address)
            setSelectedOption(stashAccounts[0])
        }
    }, [stashAccounts])


    const handleChange = (val) => {
        setSelectedOption(val)
        getRewards(val.address)

    }


    const getRewards = (address) => {
        axios.post('https://kusama.api.subscan.io/api/scan/account/reward_slash', {
            address: address,
            page: 0,
            row: 100
        }).then(res => {
            const rewardsData = res.data.data.list.reduce((prevArray, list) => {
                let params = JSON.parse(list.params)
                const itemFoundIndex = prevArray.findIndex(item => item.block_num === list.block_num)
                if (itemFoundIndex > -1) {
                    const newValue = (parseFloat(prevArray[itemFoundIndex].value) + parseFloat(params[1].value) / config.info.decimals)
                    return [...prevArray.slice(0, itemFoundIndex), {
                        block_num: list.block_num,
                        value: newValue
                    }, ...prevArray.slice(itemFoundIndex + 1)]
                } else {
                    return [...prevArray, {
                        block_num: list.block_num,
                        value: parseFloat(params[1].value) / config.info.decimals
                    }]
                }
            }, [])

            setRewards(rewardsData.reverse())

            if (rewardsData) {
                const _totalRewards = rewardsData.reduce((a, b) => {
                    return a + b.value
                }, 0)
                setTotalRewards(_totalRewards)
            }
            console.log(rewardsData)

        }).catch(error => {
            console.log(error)
        });
    }


    const renderLineChart = (
        <ResponsiveContainer width='100%'>
            <LineChart width={1200} height={800} data={rewards} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                <Line type="monotone" dataKey="value" stroke="white" strokeWidth={2}/>
                <Tooltip contentStyle={{color: "black"}}   wrapperStyle={{color: "black"}} labelStyle={{color: "black"}}     />
                <XAxis dataKey="block_num" padding={{left: 20, right: 20}}/>
                <YAxis/>
            </LineChart>
        </ResponsiveContainer>
    );

    const handleSubmit = () =>
    {
        if(stash)
        {
            console.log(stash)
            getRewards(stash)
        }

    }


    return (

        <div className='col-span-5 mt-5 mx-5'>
            <div className='flex items-center border shadow-lg p-5 bg-gray-800 text-gray-200 rounded'>
                <div className=" flex-grow pr-4">
                    <input
                        onChange={(event) => setStash(event.target.value)} type="text" placeholder="Address"
                           className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-base border-0 shadow outline-none focus:outline-none focus:ring w-full"/>
                </div>
                <button onClick={handleSubmit} className="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase px-8 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                >
                    Check
                </button>
            </div>
            <div className='h-96 border shadow-lg p-5 bg-gray-800 text-gray-200 rounded'>
                {renderLineChart}
            </div>

        </div>

    )

    if (!accounts || !connected) return (
        <div className='col-span-5 flex mt-20'>
            <div className='mx-auto text-4xl text-gray-500 font-bold'>Connect your wallet to load the dashboard!</div>
        </div>
    )

    if (!stashAccounts || !selectedOption || !stashAccounts.length) return (
        <div className='col-span-5 flex mt-20'>
            <div className='mx-auto text-4xl text-gray-500 font-bold'>Create an stash account</div>
        </div>
    )


    return (
        <div className='col-span-5 mt-5 mx-5'>
            <div><Select options={stashAccounts} name='stash'
                         defaultValue={selectedOption ? selectedOption : stashAccounts[0]}
                         onChange={(val) => handleChange(val)} className='z-0'/></div>

            <div className='grid grid-cols-2'>
                <div className='grid grid-cols-2 border border-gray-300 my-5 p-2 flex-grow flex-wrap '>
                    <div>Name</div>
                    <div>{selectedOption.name}</div>
                    <div>Stash</div>
                    <div className='truncate'>{selectedOption.stash}</div>
                    <div>Controller</div>
                    <div className='truncate'>{selectedOption.controller}</div>


                </div>
                <div className='grid grid-cols-2 border border-gray-300 my-5 p-2'>
                    <div>Balance</div>
                    <div>{(selectedOption.total_balance / config.info.decimals).toLocaleString('en-US', {
                        currency: 'KSM',
                        style: 'currency'
                    })} ({((selectedOption.total_balance / config.info.decimals) * usdTokenPrice).toLocaleString('en-US', {
                        currency: 'USD',
                        style: 'currency'
                    })})
                    </div>
                    <div>Total Rewards</div>
                    <div>{totalRewards.toLocaleString('en-US', {
                        currency: 'KSM',
                        style: 'currency'
                    })} ({(totalRewards * usdTokenPrice).toLocaleString('en-US', {currency: 'USD', style: 'currency'})})
                    </div>
                    <div>Staking since:</div>
                    <div>65 days ago</div>
                    <div>AVG APY</div>
                    <div>3%</div>
                    <div>Nominating</div>
                    <div> {selectedOption.nominators ? selectedOption.nominators.length : 0} Nominator(s)</div>

                </div>
            </div>

            <h2 className='text-gray-700 font-bold text-xl mb-3'>Rewards by Day</h2>
            <div className='h-2/3'>
                {renderLineChart}
            </div>

        </div>
    );
};

const mapStateToProps = ({data}) => ({
    accounts: data.accounts,
    ws: data.ws,
    tokens: data.tokens,
    stashAccounts: data.stashAccounts,
    connected: data.connected,
    totalBalance: data.totalBalance,
    lockedBalance: data.lockedBalance,
    usdTokenPrice: data.usdTokenPrice,
})

const mapDispatchProps = {}

export default connect(mapStateToProps, mapDispatchProps)(Dashboard)