import {ApiPromise, WsProvider} from "@polkadot/api";
import {encodeAddress} from "@polkadot/util-crypto";
import {toast} from "react-toastify";


export default class Api {

    api = null;
    provider = null;

    constructor(provider = 'wss://rpc.polkadot.io/') {
        const wsProvider = new WsProvider(provider);
        this.provider = wsProvider
    }

    async connect() {
        if (!this.api) {
            this.api = await ApiPromise.create({provider: this.provider})
            this.api.on('disconnected', (msg) => {
                console.log('disconnected')
                this.api = undefined
            })
        }
        return true
    }

    async disconnect(provider) {
        if (this.api) {
            await this.api.disconnect()
        }
    }

    async genesisHash() {
        await this.connect()
        const gh = await this.api.genesisHash.toHex()
        return gh
    }

    async getBalance(address) {
        const balance = await this.api.derive.balances.all(address)
        return balance
    }


    async getAccountData(item, token) {

        const address = item.address
        let ledger = await this.api.query.staking.ledger(address).then((ledger) => (ledger.toString() ? JSON.parse(ledger) : null))
        let bonded = await this.api.query.staking.bonded(address).then((bonded) => (bonded.toString() ? bonded.toString() : null))
        let balance = await this.api.derive.balances.all(address).then((balance) => (balance))
        let nominators = await this.api.query.staking.nominators(address).then((nominators) => (nominators.toString() ? JSON.parse(nominators).targets : null))

        return {
            address: encodeAddress(address, 2),
            name: encodeAddress(address, 2),
            label: encodeAddress(address, 2),
            stash: ledger ? ledger.stash : encodeAddress(address, 2),
            total_balance: (parseFloat(balance.availableBalance.toString()) + parseFloat(balance.reservedBalance.toString()) + parseFloat(balance.lockedBalance.toString())) / token.decimals,
            controller: bonded,
            available_balance: parseFloat(balance.availableBalance.toString()) / token.decimals,
            locked_balance: parseFloat(balance.lockedBalance.toString()) / token.decimals,
            reservered_balance: parseFloat(balance.reservedBalance.toString()) / token.decimals,
            free_balance: parseFloat(balance.freeBalance.toString()) / token.decimals,
            is_stash: bonded ? true : false,
            nominators: nominators,
            is_nominator: nominators ? true : false,
            is_controller: ledger ? true : false,
            meta: item.meta
        }

    }


    async nominateTransaction(stash, amount, targets) {
        let nominatedTargets = []

        targets.map((target) => {
            nominatedTargets = [...nominatedTargets, target.address]
        })

        let txs
        if (!stash.is_nominator) {

            txs = [
                await this.api.tx.staking.bond(stash.address, amount, 0),
                await this.api.tx.staking.nominate(nominatedTargets)
            ]
        } else {
            txs = [
                await this.api.tx.staking.nominate(nominatedTargets)
            ]
        }

        return txs
    }

    async sendTxNomination(stash,amount,targets, _injectorStash) {

        const txs = await this.nominateTransaction(stash, amount, targets)
        await this.api.tx.utility.batchAll(txs).signAndSend(stash.address, {signer: _injectorStash.signer}, ({status}) => {
            if (status.isInBlock) {
                toast.dismiss();
                toast.success(`Completed at block hash #${status.asInBlock.toString()}`)
                console.log(`Completed at block hash #${status.asInBlock.toString()}`);
                // setTxStatus({isError: false, block: status.asInBlock.toString(), error: null})
            } else {
                toast.dismiss();
                toast.error(`Current status: ${status.type}`)
            }
        }).catch((error) => {

            toast.dismiss();
            toast.error('Transaction failed')
            console.log(':( transaction failed', error);
            // setTxStatus({isError: true, block: null, error: error.toString()})
        })
    }


 async calculateTransactionFees  (stash,amount,targets)  {

    const txs = await this.nominateTransaction(stash,amount,targets)
    let paymentInfo = await this.api.tx.utility.batchAll(txs).paymentInfo(stash.address);
    const fees = parseFloat(paymentInfo.partialFee.toString(10))
    return fees
}

    async  allNominators(stash = '1sh6y8SMuseeuxn8JyW4eUHbJrLevi7ASH1TJQqb3DFjAYe') {
        try {
            console.log('getting all nominators')
            console.time();
            const exposures = await this.api.query.staking.nominators.entries();
            console.timeEnd()
            let nominators = []
            exposures.forEach(([key, exposure]) => {
                if(JSON.parse(exposure).targets.includes(stash))
                {
                    key.args.map((k) => {nominators = [...nominators,k.toHuman()]});
                }

            });
            console.log(nominators)
            return nominators

        } catch (err) {
            console.log(err);
        }
    }


}