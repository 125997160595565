import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend, CartesianGrid, AreaChart, Area} from "recharts";
import {Identicon} from "@polkadot/react-identicon";
import StakelinkAPI from "../API/StakelinkAPI";


const Validators = ({nodes, provider}) => {


    const [validator, setValidator] = useState(null);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (nodes) {
            const index = nodes.findIndex(item => item.token.token === provider.token)
            setValidator(nodes[index])
        }
    }, [])

    const slAPI = new StakelinkAPI()


    const renderLineChart = (data, dataKey, key) => (
        <ResponsiveContainer width="100%" height="100%" minHeight={300} >

            {/*<LineChart data={data} margin={{top: 5, right: 30, left: 20, bottom: 5}} syncId={key}>*/}
                <AreaChart
                    width={200}
                    height={60}
                    data={data}
                    margin={{
                        top: 5,
                        right: 0,
                        left: 0,
                        bottom: 5,
                    }}
                >

                <Legend verticalAlign="bottom" stroke='#8b5cf6' height={36}/>
                {/*<Line type="monotone" dataKey={dataKey} stroke="#7c3aed" strokeWidth={2}/>*/}
                    <Area type="monotone" dataKey={dataKey} stroke="#8884d8" fill="#8b5cf6" />

                    <Tooltip contentStyle={{background: 'white', border: 'none'}}
                         formatter={(value, entry, index) => {

                             return entry === 'rewards' || entry === 'stake' ? value.toLocaleString('en-US', {
                                 style: 'currency',
                                 currency: 'USD',
                             }) : value
                         }
                         }/>
                <XAxis dataKey="era" stroke="#888" padding={{left: 20, right: 20}}/>
                <YAxis stroke="#888" padding={{top: 20, bottom: 20, left: 20, right: 20}}/>
                    </AreaChart>
            {/*</LineChart>*/}
        </ResponsiveContainer>

    );

    const parseEras = (eras, token) => {

        return Object.entries(eras).map((era, key) => {
            return {
                era: parseInt(era[0]),
                rewards: parseFloat((era[1].rewards.amount / token.decimals) * token.price),
                stake: parseFloat((era[1].stake.total / token.decimals) * token.price),
                points: era[1].rewards.points
            }
        })


    }

    const getValidator = (stash) => {

        setLoading(true)
        slAPI.validator(stash, provider).then((validator) => {
            setValidator(validator)
            setLoading(false)
        })
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && event.target.value) {
            getValidator(event.target.value)
        }
    }


    if (loading) {
        return (
            <div className='flex col-span-5 h-screen '>
                <div className='m-auto flex'>
                    <svg className="animate-spin h-5 w-5 mr-3 bg-pink-600" viewBox="0 0 24 24"></svg>
                    <span> Loading your validator ...</span>
                </div>
            </div>)
    }

    return (
        <div className='col-span-5 px-4 py-4'>
            <h1 className='text-3xl font-bold '>Validators</h1>
            <div className='mt-2'>
                <input type='text' name='validatorStash'
                       className='border w-full h-12 p-2 text-purple-600 border-purple-700 rounded'
                       onKeyPress={handleKeyPress}/>
            </div>

            <div className='grid grid-cols-1 gap-4'>
                {validator ?

                    (
                        <div>
                            <div className='grid grid-cols-6'>
                                <div
                                    className='col-span-5 mr-2 grid grid-cols-4 shadow-md   border-gray-100 border text-gray-800 p-5 rounded my-2'>
                                    <div className='col-span-4 my-3  font-bold border-gray-300 uppercase'>Basic Info
                                    </div>
                                    <div>Network</div>
                                    <div className='col-span-3 text-purple-600'>{provider.name}</div>
                                    <div>Address</div>
                                    <div className='truncate col-span-3 text-purple-600'>{validator.token.stash}</div>
                                    <div>Commission</div>
                                    <div
                                        className='truncate col-span-3 text-purple-600'>{validator.preferences.commission / 10000000}%
                                    </div>
                                </div>
                                <div
                                    className='shadow-md grid grid-cols-4 bg-purple-500 text-gray-800 p-5 rounded my-2'>
                                    <div
                                        className='col-span-4 my-3  font-bold text-gray-100 border-gray-300 uppercase'>Score
                                    </div>
                                    <div
                                        className='truncate col-span-3 text-gray-100 text-7xl font-extrabold'>{validator.scores.rating}</div>
                                </div>
                            </div>
                            <div className='grid grid-cols-2'>

                                <div className=' shadow-md   border-gray-100 border  text-gray-800 p-5 rounded mr-2'>
                                    <div className=' col-span-2 my-3  font-bold border-gray-300 uppercase'>Performance
                                    </div>

                                    <div className='grid grid-cols-2 gap-1'>
                                        <div>AVG APR</div>
                                        <div
                                            className='text-purple-600'>{(validator.performance['total_avg_apr'] * 100).toFixed(2)}%
                                        </div>
                                        <div>APR 30 Days</div>
                                        <div
                                            className='text-purple-600'>{(validator.performance['30e_avg_apr'] * 100).toFixed(2)}%
                                        </div>
                                        <div>APR 60 Days</div>
                                        <div
                                            className='text-purple-600'>{(validator.performance['60e_avg_apr'] * 100).toFixed(2)}%
                                        </div>
                                        <div>Inclusion</div>
                                        <div
                                            className='text-purple-600'>{(validator.performance['total_inclusion'] * 100).toFixed(2)}%
                                        </div>
                                        <div>Inclusion 30 Days</div>
                                        <div
                                            className='text-purple-600'>{(validator.performance['30e_inclusion'] * 100).toFixed(2)}%
                                        </div>
                                        <div>Inclusion 60 Days</div>
                                        <div
                                            className='text-purple-600'>{(validator.performance['60e_inclusion'] * 100).toFixed(2)}%
                                        </div>
                                        <div>Active Eras</div>
                                        <div className='text-purple-600'>{validator.performance['active_eras']}</div>
                                    </div>
                                </div>
                                <div className='  shadow-md    text-gray-800 border-gray-100 border p-5'>
                                    <div className=' col-span-2 gap-1 my-3 font-bold border-gray-300 uppercase'>Stake
                                        Metrics
                                    </div>
                                    <div className='grid grid-cols-2'>
                                        <div>Total Stake</div>
                                        <div
                                            className='text-purple-600'><span>{(validator.stake.total / validator.token.decimals).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: validator.token.token,
                                        })}</span>
                                            <span className='ml-1'>
                                                  ({((validator.stake.total / validator.token.decimals) * validator.token.price).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })})
                                                    </span>
                                        </div>
                                        <div>Own Stake</div>
                                        <div
                                            className='text-purple-600'>
                                                <span>{(validator.stake.own / validator.token.decimals).toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: validator.token.token,
                                                })}</span>
                                            <span className='ml-1'>
                                                  ({((validator.stake.own / validator.token.decimals) * validator.token.price).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })})
                                                    </span>
                                        </div>
                                        <div>Others Stake</div>
                                        <div
                                            className='text-purple-600'>
                                                <span>{((validator.stake.total - validator.stake.own) / validator.token.decimals).toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: validator.token.token,
                                                })}</span>
                                            <span className='ml-1'>
                                                  ({(((validator.stake.total - validator.stake.own) / validator.token.decimals) * validator.token.price).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })})
                                                    </span>
                                        </div>
                                        <div>Active Stake</div>
                                        <div
                                            className='text-purple-600'>
                                                <span>{((validator.stake.active) / validator.token.decimals).toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: validator.token.token,
                                                })}</span>
                                            <span className='ml-1'>
                                                  ({(((validator.stake.active) / validator.token.decimals) * validator.token.price).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })})
                                                    </span>

                                        </div>
                                        <div>Total Rewards</div>
                                        <div
                                            className='text-purple-600'>
                                               <span>
                                                {((validator.rewards.amount) / validator.token.decimals).toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: validator.token.token,
                                                })}</span>

                                            <span className='ml-1'>
                                                  ({(((validator.rewards.amount) / validator.token.decimals) * validator.token.price).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })})
                                                    </span>

                                        </div>
                                        <div>Validator Own Rewards</div>
                                        <div className='text-purple-600'>
                                                <span>{((validator.rewards.amount * (validator.preferences.commission / 1000000000)) / validator.token.decimals).toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: validator.token.token,
                                                })}</span>
                                            <span className='ml-1'>
                                                  ({(((validator.rewards.amount * (validator.preferences.commission / 1000000000)) / validator.token.decimals) * validator.token.price).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })})
                                                    </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className=' shadow-md   overflow border-gray-100 border p-5 rounded my-3'>
                                <div className=' col-span-2 my-3  font-bold border-gray-300 uppercase'>Nominators
                                    ({Object.keys(validator.stake.nominators).length})
                                </div>

                                <div className='h-56 overflow-y-auto grid gap-2 grid-cols-2'>
                                    {validator && Object.entries(validator.stake.nominators).map((item) => {

                                        return (
                                            <div className='flex items-center space-x-2'>
                                                <div><Identicon
                                                    value={item[0]}
                                                    size='24'
                                                    theme='polkadot'

                                                /></div>
                                                <div className='truncate flex-grow'>{item[0]}</div>
                                                <div>({(item[1] / validator.token.decimals).toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: validator.token.token,
                                                })})
                                                </div>

                                            </div>
                                        )
                                    })}

                                </div>
                            </div>


                            <div className=' shadow-md  grid grid-cols-3 border-gray-100 border p-5 rounded '>
                                <div className=' my-3 col-span-3 font-bold border-gray-300 uppercase'>Eras Info
                                </div>

                                <div className='mt-3'>
                                    {renderLineChart(parseEras(validator.eras, validator.token), 'rewards', validator.token.stash)}
                                </div>
                                <div className='mt-3'>
                                    {renderLineChart(parseEras(validator.eras, validator.token), 'stake', validator.token.stash)}
                                </div>
                                <div className='mt-3'>
                                    {renderLineChart(parseEras(validator.eras, validator.token), 'points', validator.token.stash)}
                                </div>

                            </div>
                        </div>
                    ) : null}
            </div>

        </div>
    )
}


const mapStateToProps = ({data}) => ({
    provider: data.provider,
    nodes: data.nodes

})

const mapDispatchProps =
    {}

export default connect(mapStateToProps, mapDispatchProps)(Validators)