import React, {useEffect} from 'react';
import Select from "react-select";
import {api} from "../../API/PolkadotJS/connection";

const InputValidateController = ({options,setController,setErrors, stash, controller, setWarnings}) => {


    useEffect(() => {

        async function check(acc)  {

            const bonded = await api.then((api) =>
                api.query.staking.bonded(acc).then((res) =>
                    (res.isSome)
                ))
            const ledger = await api.then((api) =>
                api.query.staking.ledger(acc).then((res) =>
                    (res.isSome)
                ))

            const balance = await api.then((api) =>
                api.derive.balances.all(acc).then((res) =>
                    (res.freeBalance.isZero())
                ))

            return [bonded,ledger,balance]
        }

        if(controller) {
            check(controller).then(([bonded,ledger,noBalance]) => {
                let newError = null
                let newWarning = null
                if (bonded && (controller !== stash)) {
                    newError = 'A controller account should not map to another stash. This selected controller is a stash'
                } else if (ledger) {
                    newError = 'A controller account should not be set to manage multiple stashes.'
                } else if (noBalance) {
                    newError = 'The controller does no have sufficient funds available to cover transaction fees. Ensure that a funded controller is used.'
                } else if (controller === stash) {
                    newWarning = 'Distinct stash and controller accounts are recommended to ensure fund security. You will be allowed to make the transaction, but take care to not tie up all funds, only use a portion of the available funds during this period.'
                }

                setErrors(newError)
                setWarnings(newWarning)
            })
        }

    }, [controller, stash])
    return (
        <div>
            <Select options={options} onChange={(controller) => setController(controller.value)}/>

        </div>
    );
};

export default InputValidateController;