const config = {
    'polkadotvalidators.com': {
        style: {
            navbar: 'bg-white border-4-b border-b-4 border-black  fixed flex p-3 shadow-md text-white w-screen',
            image: 'polkadot.svg'
        },
        info: {
            tokenName: 'polkadot',
            token: 'DOT',
            provider: 'wss://rpc.polkadot.io/',
            decimals: 10000000000,
            domain: 'https://polkadotvalidators.com'
        }
    },
    'kusamavalidators.com': {
        style: {
            navbar: 'bg-black border-4-b border-b-4 border-pink-600 fixed flex p-3 shadow-md text-white w-screen',
            image: 'kusama.svg'
        },
        info: {
            tokenName: 'kusama',
            token: 'KSM',
            provider: 'wss://kusama-rpc.polkadot.io/',
            decimals: 1000000000000,
            domain: 'https://kusamavalidators.com'
        }
    },
    localhosst: {
        style: {
            navbar: 'bg-black border-4-b border-b-4 border-pink-600 fixed flex p-3 shadow-md text-white w-screen',
            image: 'kusama.svg'
        },
        info: {
            tokenName: 'kusama',
            token: 'KSM',
            provider: 'wss://kusama-rpc.polkadot.io/',
            decimals: 1000000000000,
            domain: 'https://kusamavalidators.com'
        }
    }
}


const hostname = window.location.hostname
export default config[hostname]
