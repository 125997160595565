import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

const Alert = ({type, message}) => {

    if (type == 'error') {
        return <div
            className='p-3 border-2 border-red-700 bg-red-50 text-red-700 col-span-2'>
            <FontAwesomeIcon icon={faExclamationTriangle}  className='mr-2 '/> {message}
        </div>
    }

    return <div
        className='p-3 border-2 border-yellow-400 bg-yellow-50 col-span-2 text-yellow-600'>
        <FontAwesomeIcon icon={faExclamationTriangle}  className='mr-2 '/>{message}
    </div>
};

export default Alert;