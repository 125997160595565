const defaultState = {accounts:null, connected:null}

const dataReducer = (state=defaultState, action) => {
    switch (action.type)
    {
        case 'setAccounts':
            return {...state, accounts: action.payload}
        case 'setStashAccounts':
            return {...state, stashAccounts: action.payload}
        case 'setConnected':
            return {...state, connected: action.payload}
        case 'setUSDTokenPrice':
            return {...state, usdTokenPrice: action.payload}
        case 'setTokens':
            return {...state, tokens: action.payload}
        case 'setProvider':
            return {...state, provider: action.payload}
        case 'setWs':
            return {...state, ws: action.payload}
        case 'setAppReady':
            return {...state, appReady: action.payload}
        case 'setConnection':
            return {...state, connection: action.payload}
        case 'setNodes':
            return {...state, nodes: action.payload}
        case 'setTotalBalance':
            return {...state, totalBalance: action.payload}
        case 'setRankings':
            return {...state, rankings: action.payload}
        case 'setLockedBalance':
            return {...state, lockedBalance: action.payload}


        default:
            return state
    }
}

export default dataReducer
