import {createStore, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
// stoped using devtools in production
import reducers from "./reducers";

const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 25
})) || compose
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = (initialStore) => {
    return createStore(
        reducers,
        initialStore,
        composeEnhancers(applyMiddleware(thunk))
    )
}

export default store