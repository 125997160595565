import React, {useEffect, useState} from 'react';

import config from "./topvalidators.config"
import axios from "axios";
import {boot} from "./store/dataActions";
import {connect} from "react-redux";
import Sidebar from "./components/Sidebar/Sidebar";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Stake from "./components/Stake"
import {ToastContainer} from "react-toastify";
import Validators from "./components/Validators";

const App = ({boot, appReady}) => {


    const [targets, setTargets] = useState([]);


    useEffect(() => {
        boot()

    }, [])


    return (
        <Router>
            <div className="app  ">

                {!appReady ?
                    <div className='flex h-screen '>
                        <div className='m-auto flex'>
                            <svg className="animate-spin h-5 w-5 mr-3 bg-pink-600" viewBox="0 0 24 24"></svg>
                            <span>Connecting to the network ...</span>
                        </div>
                    </div>
                    :
                    <div className='grid grid-cols-6 relative'>
                        <Sidebar/>

                        <Switch>
                            <Route path='/' exact={true}>
                                <Stake targets={targets} setTargets={setTargets}/>
                            </Route>
                            <Route path='/dashboard' exact={true} component={Dashboard}/>
                            <Route path="/stake" exact={true}>
                                <Stake targets={targets} setTargets={setTargets}/>
                            </Route>
                            <Route path="/validators" exact={true}>
                                <Validators />
                            </Route>

                        </Switch>

                    </div>


                }
                <ToastContainer className='z-50'/>

            </div>
        </Router>)


}


const mapStateToProps = ({data}) => ({
    accounts: data.accounts,
    appReady: data.appReady,
    connected: data.connected
})

const mapDispatchProps = {
    boot
}

export default connect(mapStateToProps, mapDispatchProps)(App);