import React from 'react'
import stakelinklogo from '../../img/stakelinkwhite.png'
import {faCoins, faServer} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ConnectButton from "../ConnectButton";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {setProvider} from "../../store/dataActions";

const Sidebar = ({tokens, provider, setProvider}) => {


    return(

        <aside id="sidebar" className='bg-gray-900 h-screen sticky top-0 border-r-8 border-gray-800'>
            <div className='mt-12 mb-8'>
                <img src={stakelinklogo} className='w-52 mx-auto'/>
            </div>

            <div className='mx-auto mt-10 flex justify-center'>
                <ConnectButton />
            </div>
            <div className='flex justify-center mt-5'>
                {tokens &&  provider && tokens.map((item, i) => {

                        if (item.name == provider.name)
                            return <button key={i}
                                className='border py-2 px-6 bg-pink-500 text-white' onClick={() => setProvider(item)}>{item.name}</button>
                        else
                            return <button key={i} className='border py-2 px-6 text-white' onClick={() => setProvider(item)}>{item.name}</button>


                    }
                )}
            </div>

            <ul className='flex flex-col space-y-2 pt-3'>
                {/*<li className='bg-gray-800 rounded p-2 text-white font-bold mx-4 hover:bg-gray-700 cursor-pointer'>*/}
                {/*    <Link to="/dashboard" className='flex flex-grow items-center'><FontAwesomeIcon icon={faHome} className='mr-2'/> Dashbord</Link>*/}
                {/*</li>*/}
                <li className='bg-gray-800 rounded p-2 text-white font-bold mx-4 hover:bg-gray-700 cursor-pointer'>
                    <Link to="/stake" className='flex flex-grow items-center'><FontAwesomeIcon icon={faCoins} className='mr-2'/>Stake</Link>
                </li>
                <li className='bg-gray-800 rounded p-2 text-white font-bold mx-4 hover:bg-gray-700 cursor-pointer'>
                    <Link to="/validators" className='flex flex-grow items-center'><FontAwesomeIcon icon={faServer} className='mr-2'/>Validators</Link>
                </li>


            </ul>
        </aside>
    )



}


const mapStateToProps = ({data}) => ({
    tokens: data.tokens,
    provider: data.provider
})

const mapDispatchProps = {
    setProvider
}

export default connect(mapStateToProps, mapDispatchProps)(Sidebar)