import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Identicon from "@polkadot/react-identicon";

const Ranking = ({rankings, provider}) => {

    const [ranking, setRanking] = useState([]);


    useEffect(() => {
        if(rankings) {
            const index = rankings.findIndex(item => item.token === provider.token)
            setRanking(rankings[index].data)
        }
    }, [rankings])


    return (
        <div>

            <table className="table-auto  w-full col-span-2">
                <thead className="bg-gray-700 text-white">
                <tr>
                    <th className="py-3">Stash</th>
                    <th className="">Inclusion</th>
                    <th className="">APR</th>
                    <th className="">Rating</th>


                </tr>
                </thead>
                <tbody>
                {ranking && ranking.slice(0,200).map((item) => {
                    return (
                        <tr>
                            <td className='items-center flex'>
                                <Identicon
                                value={item.id}
                                size='24'
                                theme='polkadot'

                            />
                                {item.id}
                            </td>
                            <td>{(item.performance['30e_inclusion'] * 100).toFixed(2)}%</td>
                            <td>{(item.performance['30e_avg_apr'] * 100).toFixed(2)}%</td>
                            <td>{item.scores.rating}</td>

                        </tr>
                    )
                })}
                </tbody>
            </table>


        </div>
    );
};

const mapStateToProps = ({data}) => ({
    rankings: data.rankings,
    provider: data.provider

})

const mapDispatchProps = {}

export default connect(mapStateToProps, mapDispatchProps)(Ranking);