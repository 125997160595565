import React, {useState, useEffect} from 'react';
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {getAccounts, setConnected} from "../store/dataActions";
import {connect} from "react-redux";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ConnectButton = ({accounts, setConnected, connected, getAccounts}) => {

    const [connecting, setConnecting] = useState(null);


    useEffect(() => {
        if (connected) {
            toast.success("Connected!");
            setConnecting(false)

        }else if( connected === false)
        {
            setConnecting(false)
        }

    }, [connected])

    const handleConnect = () => {
        setConnected()
        setConnecting(true)

    }


    if (connecting) {
        return (
            <button
                className="border-2 border-pink-500 font-bold px-8 py-1  w-52 rounded-2xl text-white focus:outline-none bg-pink-500  ">
                <div className='flex items-center'><svg className="animate-spin h-2 w-2 mr-3 bg-white" viewBox="0 0 24 24"></svg>
                Connecting...
                </div>
            </button>
        )
    }

    return (
        <div>
            {connected && accounts && accounts.length ?
                <button
                    className="border-2 border-green-700 font-bold px-8 py-1 w-52 bg-green-600 rounded-2xl text-white focus:outline-none cursor-not-allowed ">
                    <FontAwesomeIcon icon={faCircle} className='pr-1 text-green-400'/> Connected
                </button>
                :
                <button
                    className="border-2 border-gray-300 font-bold px-8 py-1 w-52  bg-white text-gray-700 rounded-2xl  focus:outline-none  "
                    onClick={handleConnect}>
                    <FontAwesomeIcon icon={faCircle} className='pr-1 text-red-600'/> Connect Wallet
                </button>}

        </div>
    )
}

const mapStateToProps = ({data}) => ({
    accounts: data.accounts,
    connected: data.connected
})

const mapDispatchProps = {
    getAccounts, setConnected
}

export default connect(mapStateToProps, mapDispatchProps)(ConnectButton);